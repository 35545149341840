// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landingpage {
  display: flex;
  justify-content: space-between; }
  .landingpage__leftSide {
    display: flex;
    flex-direction: column;
    gap: 1rem; }
  .landingpage__center-categoryitems {
    background-color: white;
    padding-top: 8px;
    margin-top: 1rem;
    max-width: 658px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .landingpage__rightSide {
    display: flex;
    flex-direction: column;
    gap: 1rem; }
    .landingpage__rightSide-screens {
      display: flex;
      flex-direction: column;
      gap: 1rem; }
  .landingpage .deals {
    text-decoration: none; }
`, "",{"version":3,"sources":["webpack://./src/components/pages/desktop/desktop.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B,EAAA;EAE9B;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS,EAAA;EAIT;IACE,uBAAuB;IACvB,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB,EAAA;EAIvB;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS,EAAA;IAET;MACE,aAAa;MACb,sBAAsB;MACtB,SAAS,EAAA;EA/Bf;IAoCI,qBAAqB,EAAA","sourcesContent":[".landingpage {\n  display: flex;\n  justify-content: space-between;\n\n  &__leftSide {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n  }\n\n  &__center {\n    &-categoryitems {\n      background-color: white;\n      padding-top: 8px;\n      margin-top: 1rem;\n      max-width: 658px;\n      height: 400px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n\n  &__rightSide {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n\n    &-screens {\n      display: flex;\n      flex-direction: column;\n      gap: 1rem;\n    }\n  }\n\n  .deals {\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
