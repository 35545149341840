/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props) => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.9667 27.3334V17.0067H22.4334L22.9521 12.9827H18.9654V10.4134C18.9654 9.24808 19.2894 8.45341 20.9614 8.45341H23.0921V4.85341C22.0602 4.74361 21.0231 4.69019 19.9854 4.69341C16.9134 4.69341 14.8107 6.56941 14.8107 10.0134V12.9827H11.3334V17.0067H14.8094V27.3334H2.13875C1.32541 27.3334 0.666748 26.6747 0.666748 25.8614V2.13875C0.666748 1.32541 1.32541 0.666748 2.13875 0.666748H25.8614C26.6747 0.666748 27.3334 1.32541 27.3334 2.13875V25.8614C27.3334 26.6747 26.6747 27.3334 25.8614 27.3334H18.9667Z"
      fill="white"
    />
  </svg>
);

export default SVG;
