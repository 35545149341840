import GraphQlClient from "konga-graphql-client";
// import * as CONSTANTS from "../helpers/constants";
import { pageContent } from "../queries/homepage";
import { CONSTANTS } from "../helpers/constants";

const graphqlClient = new GraphQlClient(
  process.env.REACT_APP_GRAPHQL_SERVER_BROWSER_URL,
  []
);

const headersObject = {
  "X-App-Source": CONSTANTS.KONGA_NOW_HEADER,
};

export const homepageData = (slug) => {
  return graphqlClient
    .setRequestHeaders(headersObject)
    .query(pageContent(slug));
};
