import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/ui/layout/layout";
import "./viewpage.scss";
import Viewpage from "../../components/ui/viewpage/viewpage";

const View = () => {
  const { id } = useParams();

  const youtubeURL = `https://www.youtube.com/watch?v=${id}`;

  return (
    <Layout>
      <Viewpage youtubeURL={youtubeURL} />
    </Layout>
  );
};

export default View;
