import React from "react";
import Icon from "../../../assets/Icon/icon";
import Streamscreen from "../streamscreen/streamscreen";
import "./viewpage.scss";
import { Link } from "react-router-dom";

const Viewpage = ({ youtubeURL }) => {
  return (
    <div className="viewpage">
      <Link to="/">
        <div className="return">
          <Icon name="backarrow" />
          <p>Back to Home Page</p>
        </div>
      </Link>
      <Streamscreen large youtubeURL={youtubeURL} />
    </div>
  );
};

export default Viewpage;
