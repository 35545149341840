// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landingpage {
  display: flex;
  justify-content: space-between; }
  .landingpage_leftSide {
    display: flex;
    flex-direction: column;
    gap: 1rem; }
  .landingpage_center__categoryitems {
    background-color: white;
    padding: 8px 0;
    margin-top: 1rem;
    gap: 24px;
    flex-wrap: wrap;
    max-width: 658px;
    display: flex;
    justify-content: center; }
  .landingpage_rightSide {
    display: flex;
    flex-direction: column;
    gap: 1rem; }
`, "",{"version":3,"sources":["webpack://./src/components/ui/skeleton/skeleton.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,8BAA8B,EAAA;EAE9B;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS,EAAA;EAIT;IACE,uBAAuB;IACvB,cAAc;IACd,gBAAgB;IAChB,SAAS;IACT,eAAe;IACf,gBAAgB;IAChB,aAAa;IACb,uBAAuB,EAAA;EAI3B;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS,EAAA","sourcesContent":[".landingpage {\n    display: flex;\n    justify-content: space-between;\n  \n    &_leftSide {\n      display: flex;\n      flex-direction: column;\n      gap: 1rem;\n    }\n  \n    &_center {\n      &__categoryitems {\n        background-color: white;\n        padding: 8px 0;\n        margin-top: 1rem;\n        gap: 24px;\n        flex-wrap: wrap;\n        max-width: 658px;\n        display: flex;\n        justify-content: center;\n      }\n    }\n  \n    &_rightSide {\n      display: flex;\n      flex-direction: column;\n      gap: 1rem;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
