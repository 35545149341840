/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props) => (
  <svg
    width="28"
    height="20"
    viewBox="0 0 28 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.31878 0.519985C11.7662 0.172049 16.234 0.172049 20.6814 0.519985L23.6681 0.754652C24.477 0.817714 25.2416 1.14972 25.8399 1.69777C26.4382 2.24581 26.8358 2.97836 26.9694 3.77865C27.6588 7.89865 27.6588 12.1027 26.9694 16.2213C26.8359 17.0214 26.4384 17.7538 25.8404 18.3018C25.2424 18.8498 24.4781 19.182 23.6694 19.2453L20.6828 19.4786C16.2349 19.8267 11.7666 19.8267 7.31878 19.4786L4.33211 19.2453C3.5232 19.1823 2.75866 18.8502 2.16035 18.3022C1.56205 17.7542 1.1644 17.0216 1.03078 16.2213C0.342605 12.1023 0.342605 7.89766 1.03078 3.77865C1.16434 2.97856 1.56179 2.24618 2.15983 1.69816C2.75787 1.15014 3.5221 0.817999 4.33078 0.754652L7.31745 0.521318L7.31878 0.519985ZM11.3334 13.2933V6.70665C11.3336 6.63596 11.3524 6.56657 11.388 6.50553C11.4237 6.44449 11.4749 6.39399 11.5364 6.35916C11.5979 6.32434 11.6676 6.30644 11.7382 6.30729C11.8089 6.30814 11.8781 6.3277 11.9388 6.36398L17.4281 9.65732C17.4872 9.69289 17.5361 9.74314 17.57 9.80318C17.6039 9.86322 17.6218 9.93102 17.6218 9.99998C17.6218 10.0689 17.6039 10.1367 17.57 10.1968C17.5361 10.2568 17.4872 10.3071 17.4281 10.3427L11.9388 13.636C11.8781 13.6723 11.8089 13.6918 11.7382 13.6927C11.6676 13.6935 11.5979 13.6756 11.5364 13.6408C11.4749 13.606 11.4237 13.5555 11.388 13.4944C11.3524 13.4334 11.3336 13.364 11.3334 13.2933Z"
      fill="white"
    />
  </svg>
);

export default SVG;
