import React from "react";
import "./advert.scss";
import adGif from "../../../assets/images/ad.gif";
import { Contact } from "./advertData";
import Icon from "../../../assets/Icon/icon";

const Advert = () => {
  return (
    <div className="advert">
      <div>
        <h4>Advert Placement</h4>
      </div>
      <div className="advert_gif">
        <img src={adGif} alt="advert" />
      </div>
      <div className="advert__content">
        <p>Chat Us on WhatsApp or Call the number</p>

        <div className="advert__content__info">
          {Contact.map((data, index) => {
            return (
              <div key={index} className="advert__content__info-contact">
                <Icon name={data.icon} />
                <a href={data.info}>{data.contact}</a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Advert;
