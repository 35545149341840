import React from "react";
import "./disclaimer.scss";

const Disclaimer = () => {
  return (
    <div className="disclaimer">
      <div className="disclaimer__title">
        <h2>Disclaimer</h2>
      </div>
      <div className="disclaimer__text">
        <p>
          KongaTV serves as an e-commerce seller's platform affiliated with the
          Konga Group. It's important to note that all products mentioned on
          KongaTV are exclusively available on konga.com, and every product is
          both sold and shipped via konga.com.
        </p>
      </div>
    </div>
  );
};

export default Disclaimer;
