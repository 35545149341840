import React, { useEffect, useState } from "react";
import "./dailyDeals.scss";

const DailyDeals = ({ img }) => {
  const [imageData, imageDataMobile] = img;
  const { alt, image, link } = imageData || {};
  const {
    alt: altMobile,
    image: imageMobile,
    link: linkMobile,
  } = imageDataMobile || {};

  console.log("desktop",alt, image, link )
  console.log("mobile",altMobile, imageMobile, linkMobile )

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // You can adjust the threshold as needed

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update the state based on the window width
    };

    // Add an event listener to check for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMobile]);

  return (
    <>
      {isMobile ? (
        <div className="dailyDealsMobile">
          <a href={linkMobile} target="_blank" rel="noreferrer">
            <img src={imageMobile} alt={altMobile} />
          </a>
        </div>
      ) : (
        <div className="dailyDeals">
          <a href={link} target="_blank" rel="noreferrer">
            <img src={image} alt={alt} />
          </a>
        </div>
      )}
    </>
  );
};

export default DailyDeals;
