/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props) => (
  <svg
    width="206"
    height="59"
    viewBox="0 0 206 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="206" height="58.3398" fill="url(#pattern0)" />
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use
          transform="scale(0.000244141 0.000862069)"
        />
      </pattern>
      <image
        id="image0_1536_2723"
        width="4096"
        height="1160"
      />
    </defs>
  </svg>
);

export default SVG;
