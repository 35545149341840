import React from "react";
import "./skeleton.scss";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = () => {
  const itemsArray = Array.from({ length: 2 }, (_, index) => ({
    id: index + 1,
  }));

  const categoryArray = Array.from({ length: 8 }, (_, index) => ({
    id: index + 1,
  }));
  return (
    <div className="landingpage">
      <div className="landingpage_leftSide">
        {itemsArray.map((_, index) => (
          <Skeleton width={319} height={319} key={index} />
        ))}
      </div>
      <div className="landingpage_center">
        <Skeleton width={658} height={424} />

        <div className="landingpage_center__categoryitems">
          {categoryArray.map((_, index) => (
            <Skeleton width={120} height={120} key={index} />
          ))}
        </div>
      </div>
      <div className="landingpage_rightSide">
        {itemsArray.map((_, index) => (
          <Skeleton width={319} height={319} key={index} />
        ))}
      </div>
    </div>
  );
};

export default SkeletonLoader;
