import React from "react";
import "./desktop.scss";
import Boxbanner from "../../ui/boxbanner/boxbanner";
// import { Newscards } from "./desktopData";
// import Newscardsection from "../../ui/newscardsection/newscardsection";
import Streamscreen from "../../ui/streamscreen/streamscreen";
// import Categorybanner from "../../ui/categorybanner/categorybanner";
import SkeletonLoader from "../../ui/skeleton/skeleton";
import { Link } from "react-router-dom";
import Deals from "../../ui/deals/deals";
import { extractYouTubeVideoId } from "../../../libs/utils";
import Advert from "../../ui/advert/advert";
import Disclaimer from "../../ui/disclaimer/disclaimer";
import DailyDeals from "../../ui/dailyDeals/dailyDeals";

const Desktop = ({
  pending,
  // banners,
  leftBanners,
  rightBanners,
  // categories,
  youtubeURL,
  // deals,
  dailyDeals,
}) => {
  return (
    <div>
      {pending ? (
        <SkeletonLoader />
      ) : (
        <>
          <div className="landingpage">
            <div className="landingpage__leftSide">
              {/* RETURN TO "banners" AFTER LAUNCH */}
              {leftBanners.map((banner, index) => (
                <Boxbanner
                  key={index}
                  image={banner.image}
                  alt={banner.alt_text}
                  link={banner.link}
                />
              ))}

              <Disclaimer />

              {/* {Newscards.map((newscard, index) => (
              <Newscardsection
                key={index}
                mainTitle={newscard.mainTitle}
                section={newscard.section}
                title={newscard.title}
                info={newscard.info}
              />
            ))} */}
            </div>
            <div className="landingpage__center">
              <Streamscreen youtubeURL={youtubeURL} main title="Konga Today" />

              {/* <div className="landingpage__center-categoryitems">
                {categories.map((category, index) => (
                  <Categorybanner
                    key={index}
                    image={category.image}
                    title={category.alt_text}
                    link={category.link}
                  />
                ))}
              </div> */}

              <div className="landingpage__center-categoryitems">
                <DailyDeals img={dailyDeals} />
              </div>

              {/* <div className="landingpage_center__newsitems">
              {Newscards.map((newscard, index) => (
                <Newscardsection
                  key={index}
                  mainTitle={newscard.mainTitle}
                  section={newscard.section}
                  title={newscard.title}
                  info={newscard.info}
                  center
                />
              ))}
            </div> */}
            </div>
            <div className="landingpage__rightSide">
              {/* COMMENT AFTER LAUNCH */}
              {rightBanners.map((rightBanner, index) => (
                <Boxbanner
                  key={index}
                  image={rightBanner.image}
                  alt={rightBanner.alt_text}
                  link={rightBanner.link}
                />
              ))}
              <div className="landingpage__rightSide-screens">
                {/* UNCOMMENT AFTER LAUNCH */}
                {/* {deals.map((deal, index) => {
                  const youtubeID = extractYouTubeVideoId(deal.url);

                  return (
                    <Link to={`/${youtubeID}`} key={index} className="deals">
                      <Deals videoID={youtubeID} title={deal.text} />
                    </Link>
                  );
                })} */}
              </div>

              <div>
                <Advert />
              </div>

              {/* {Newscards.map((newscard, index) => (
              <Newscardsection
                key={index}
                mainTitle={newscard.mainTitle}
                section={newscard.section}
                title={newscard.title}
                info={newscard.info}
              />
            ))} */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Desktop;
