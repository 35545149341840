import React from "react";
import "./navbar.scss";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <Link to="/">
      <div className="navbar">
        <img src={logo} alt="logo" />
      </div>
    </Link>
  );
};

export default Navbar;
