/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1658_3110)">
      <path
        d="M3.63605 11.293C3.44858 11.4805 3.34326 11.7348 3.34326 12C3.34326 12.2652 3.44858 12.5195 3.63605 12.707L9.29305 18.364C9.48165 18.5462 9.73425 18.6469 9.99645 18.6447C10.2586 18.6424 10.5095 18.5372 10.6949 18.3518C10.8803 18.1664 10.9854 17.9156 10.9877 17.6534C10.99 17.3912 10.8892 17.1386 10.707 16.95L6.75705 13H20C20.2653 13 20.5196 12.8946 20.7072 12.7071C20.8947 12.5196 21 12.2652 21 12C21 11.7348 20.8947 11.4804 20.7072 11.2929C20.5196 11.1054 20.2653 11 20 11H6.75705L10.707 7.05C10.8892 6.8614 10.99 6.60879 10.9877 6.3466C10.9854 6.0844 10.8803 5.83359 10.6949 5.64818C10.5095 5.46277 10.2586 5.3576 9.99645 5.35532C9.73425 5.35305 9.48165 5.45384 9.29305 5.636L3.63605 11.293Z"
        fill="#ED017F"
      />
    </g>
    <defs>
      <clipPath id="clip0_1658_3110">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
