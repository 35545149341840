/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props) => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.8333 16C29.8326 12.464 28.4276 9.07303 25.9273 6.5727C23.4269 4.07236 20.036 2.66738 16.5 2.66667V5.33334C18.6094 5.33382 20.6714 5.95952 22.4254 7.13139C24.1794 8.30326 25.5467 9.96871 26.3546 11.9173C26.8908 13.2117 27.1667 14.599 27.1666 16H29.8333ZM3.16663 13.3333V6.66667C3.16663 6.31305 3.3071 5.97391 3.55715 5.72386C3.8072 5.47381 4.14634 5.33334 4.49996 5.33334H11.1666C11.5202 5.33334 11.8594 5.47381 12.1094 5.72386C12.3595 5.97391 12.5 6.31305 12.5 6.66667V12C12.5 12.3536 12.3595 12.6928 12.1094 12.9428C11.8594 13.1929 11.5202 13.3333 11.1666 13.3333H8.49996C8.49996 16.1623 9.62376 18.8754 11.6242 20.8758C13.6245 22.8762 16.3376 24 19.1666 24V21.3333C19.1666 20.9797 19.3071 20.6406 19.5571 20.3905C19.8072 20.1405 20.1463 20 20.5 20H25.8333C26.1869 20 26.5261 20.1405 26.7761 20.3905C27.0261 20.6406 27.1666 20.9797 27.1666 21.3333V28C27.1666 28.3536 27.0261 28.6928 26.7761 28.9428C26.5261 29.1929 26.1869 29.3333 25.8333 29.3333H19.1666C10.3306 29.3333 3.16663 22.1693 3.16663 13.3333Z"
      fill="black"
    />
    <path
      d="M23.8907 12.9387C24.2931 13.9091 24.5002 14.9494 24.5 16H22.1C22.1002 15.2645 21.9554 14.5363 21.6741 13.8568C21.3927 13.1773 20.9802 12.5599 20.4602 12.0398C19.9401 11.5198 19.3227 11.1073 18.6432 10.8259C17.9637 10.5446 17.2355 10.3998 16.5 10.4V8C18.0822 8.00008 19.6289 8.46933 20.9444 9.3484C22.2599 10.2275 23.2852 11.4769 23.8907 12.9387Z"
      fill="black"
    />
  </svg>
);

export default SVG;
