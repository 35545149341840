/* eslint-disable no-useless-escape */
/**
 * Checks if an array is empty
 * @param {Array} arr Array to be tested
 * @returns {Boolean} Boolean value
 */
export const isNotEmptyArray = (arr) => Array.isArray(arr) && arr.length > 0;

/**
 * Util to convert a string to a slug format
 * @param {string} text The string to format
 * @returns {string} The resulting string
 */
export const slugify = (text) =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "_") // Replace spaces with _
    .replace(/\-+/g, "_") // Replace all - with _
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\_\_+/g, "_") // Replace multiple _ with single _
    .replace(/^_+/, "") // Trim _ from start of text
    .replace(/_+$/, ""); // Trim _ from end of text

/**
 * Ensure that a given string matches the character count and ellipsized at that point
 * @param {String} text Target text
 * @param {Number} numChars Number of characters needed
 * @returns {String} Truncated text
 */
export const truncateMultilineText = (text, numChars) => {
  if (!text) return "";

  // Because '...' will be appended to long strings,
  // this ensures that the entire character count is as specified
  const maxStringLength = numChars - 3;

  return maxStringLength > text.length
    ? text
    : `${text.trim().substring(0, maxStringLength)}...`;
};

/**
 * Normalize page contents fetched from Content Service
 * @param {*} data Response data
 * @param { bool } traverse Whether to normalize cards as well
 * @returns { object } Section Object
 */
export const normalizePageContent = (data, traverse) => {
  const sections = data && data.sectionData && data.sectionData.sections;
  const sectionObj =
    isNotEmptyArray(sections) &&
    sections.reduce((acc, val) => {
      const section = val.section;
      const key = section.title;
      const value = traverse
        ? normalizeContentCards(section.cards)
        : section.cards;

      return Object.assign({}, acc, { [key]: value });
    }, {});

  return sectionObj;
};

/**
 * Normalize page cards
 * @param { array } cards Original array of cards
 * @returns { array } Normalized Array of cards
 */
export const normalizeContentCards = (cards) => {
  return isNotEmptyArray(cards)
    ? cards
        .sort((a, b) => a.position - b.position)
        .map((card) => {
          return card.content.reduce((acc, item) => {
            const key = slugify(
              item.template_field
                ? item.template_field.template_label
                : item.title
            );
            const value = item.data;

            acc[key] = value;
            return acc;
          }, {});
        }, {})
    : [];
};

export const extractYouTubeVideoId = (url) => {
  if (typeof url === "string") {
    const regex = /[?&]v=([^&]+)/;
    const match = url.match(regex);

    if (match && match[1]) {
      return match[1];
    }
  }

  // Handle invalid or unsupported URL formats
  return null;
};
