import React from "react";
import "./footer.scss";
import { Contact } from "../prelaunch/prelunchData";
import Icon from "../../../assets/Icon/icon";

const Footer = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <div className="footer">
      <div className="footer__info">
        <div className="contact">
          <div>
            <p>Follows on on Social Media</p>
          </div>
          <div className="contact__info">
            {Contact.map((data, index) => {
              return (
                <div key={index} className="contact__info__item">
                  <Icon name={data.icon} className="contact__info__item-icon" />
                  <a href={data.info}>{data.contact}</a>
                </div>
              );
            })}
          </div>
        </div>

        <div className="contactWhatsApp">
          <div>
            <p>Chats on Whatsapp</p>
          </div>
          <div className="contactWhatsApp__info">
            <div className="contactWhatsApp__info__item">
              <Icon
                name="whatsappPink"
                className="contactWhatsApp__info__item-icon"
              />
              <a href="whatsapp://send?text=Welcome to KongaTv!&phone=09070038400">
                09070038400
              </a>
            </div>
          </div>
        </div>
      </div>

      <div>
        <p>Copyright © {currentYear} KongaTV.com. Part of the Konga Group.</p>
      </div>
    </div>
  );
};

export default Footer;
