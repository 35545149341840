import { homepageData } from "../models/homepage.model";

export default class Homepage {
  static async homepageData(slug) {
    return await homepageData(slug)
      .then((res) => res.getPageContent)
      .catch((err) => {
        console.error(
          `[AccountService] Error while submitting login data,  error: ${err}`
        );
      });
  }
}
