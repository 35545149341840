import React from "react";
import "./boxbanner.scss";

const Boxbanner = ({ image, alt, link }) => {
  
  return (
    <div className="banner">
      <a href={link} target="_blank" rel="noreferrer">
        <img src={image} alt={alt} />
      </a>
    </div>
  );
};

export default Boxbanner;