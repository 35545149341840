import React from "react";
import "./homepage.scss";
import Layout from "../../components/ui/layout/layout";
import useContent from "../../hooks/useContent";
import Desktop from "../../components/pages/desktop/desktop";
import Mobile from "../../components/pages/mobile/mobile";

const Home = () => {
  const [
    pending,
    banners,
    leftBanners,
    rightBanners,
    categories,
    deals,
    youtubeURL,
    dailyDeals,
  ] = useContent();

  return (
    <Layout>
      <div className="desktop">
        <Desktop
          pending={pending}
          // banners={banners}
          leftBanners={leftBanners}
          rightBanners={rightBanners}
          categories={categories}
          youtubeURL={youtubeURL}
          deals={deals}
          dailyDeals={dailyDeals}
        />
      </div>

      <div className="mobile">
        <Mobile
          pending={pending}
          leftBanners={leftBanners}
          rightBanners={rightBanners}
          categories={categories}
          youtubeURL={youtubeURL}
          deals={deals}
          dailyDeals={dailyDeals}
        />
      </div>
    </Layout>
  );
};

export default Home;
