import React from "react";
import "./categorybanner.scss";

const Categorybanner = ({ image, title, link }) => {
  return (
    <div className="category">
      <a href={link} target="_blank" rel="noreferrer">
      <div className="category_image">
        <img src={image} alt={title} />
      </div>
      <div className="category_title">{title}</div>
      </a>
    </div>
  );
};

export default Categorybanner;
