export const Contact = [
  {
    icon: "whatsapp",
    info: "whatsapp://send?text=Welcome to KongaTv!&phone=09070038400",
    contact: "09070038400",
  },
  {
    icon: "call",
    info: "tel:07080635705",
    contact: "07080635705",
  },
  {
    icon: "mail",
    info: "mailto:info@kongatv.com",
    contact: "info@kongatv.com",
  },
];
