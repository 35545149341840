/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props) => (
  <svg
    width="28"
    height="22"
    viewBox="0 0 28 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.3333 3C27.3333 1.53334 26.1333 0.333336 24.6666 0.333336H3.33329C1.86663 0.333336 0.666626 1.53334 0.666626 3V19C0.666626 20.4667 1.86663 21.6667 3.33329 21.6667H24.6666C26.1333 21.6667 27.3333 20.4667 27.3333 19V3ZM24.6666 3L14 9.66667L3.33329 3H24.6666ZM24.6666 19H3.33329V5.66667L14 12.3333L24.6666 5.66667V19Z"
      fill="black"
    />
  </svg>
);

export default SVG;
