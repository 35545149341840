import React from "react";
import "./deals.scss";
import placeholder from "../../../assets/images/placeholder.png";

const Deals = ({ videoID, title }) => {
  const image = `https://img.youtube.com/vi/${videoID}/hqdefault.jpg`;

  return (
    <div className="deals">
      <div className="deals__title">
        <h3>{title}</h3>
      </div>
      <div className="deals__content">
        <img src={image} alt={placeholder} />
      </div>
    </div>
  );
};

export default Deals;
