/* eslint-disable max-len */
import React from "react";
/**
 * Account Profile Icon
 * @param {Object} props Component props
 * @returns {React.Component} React component
 */
const SVG = (props) => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1687_3162)">
      <path
        d="M1.18283 15.932C1.18208 18.6416 1.89008 21.2874 3.23633 23.6194L1.05408 31.5871L9.20808 29.4491C11.4634 30.6769 13.9903 31.3202 16.5581 31.3204H16.5648C25.0417 31.3204 31.9421 24.4225 31.9457 15.9441C31.9473 11.8358 30.3488 7.9725 27.4446 5.066C24.5408 2.15975 20.679 0.558378 16.5642 0.556503C8.08633 0.556503 1.18645 7.454 1.18295 15.932"
        fill="url(#paint0_linear_1687_3162)"
      />
      <path
        d="M0.63375 15.927C0.632875 18.7341 1.36625 21.4745 2.7605 23.89L0.5 32.1434L8.94637 29.9287C11.2736 31.1976 13.8939 31.8666 16.5601 31.8676H16.567C25.348 31.8676 32.4963 24.7216 32.5 15.9397C32.5015 11.6838 30.8455 7.68162 27.8375 4.671C24.8291 1.66075 20.8291 0.00175 16.567 0C7.7845 0 0.63725 7.145 0.63375 15.927ZM5.66375 23.474L5.34837 22.9734C4.02262 20.8654 3.32288 18.4294 3.32387 15.928C3.32675 8.62863 9.26725 2.69 16.572 2.69C20.1095 2.6915 23.434 4.0705 25.9345 6.5725C28.4349 9.07475 29.8108 12.401 29.8099 15.9388C29.8066 23.2381 23.866 29.1775 16.567 29.1775H16.5618C14.1851 29.1763 11.8542 28.538 9.8215 27.3319L9.33775 27.045L4.3255 28.3591L5.66375 23.4739V23.474Z"
        fill="url(#paint1_linear_1687_3162)"
      />
      <path
        d="M12.5847 9.2685C12.2864 8.60563 11.9726 8.59225 11.6889 8.58063C11.4567 8.57063 11.1912 8.57138 10.9259 8.57138C10.6604 8.57138 10.2291 8.67125 9.86445 9.06938C9.49945 9.46788 8.47095 10.4309 8.47095 12.3895C8.47095 14.3483 9.89757 16.2411 10.0964 16.507C10.2956 16.7724 12.8506 20.9204 16.8971 22.5161C20.2601 23.8423 20.9444 23.5785 21.6743 23.512C22.4043 23.4458 24.0298 22.5493 24.3614 21.6196C24.6933 20.6901 24.6933 19.8934 24.5938 19.7269C24.4943 19.561 24.2288 19.4614 23.8307 19.2624C23.4324 19.0633 21.4752 18.1001 21.1103 17.9673C20.7453 17.8345 20.4799 17.7683 20.2144 18.1669C19.9489 18.5649 19.1866 19.4614 18.9542 19.7269C18.7221 19.993 18.4897 20.0261 18.0917 19.827C17.6933 19.6273 16.4112 19.2074 14.8901 17.8513C13.7066 16.796 12.9076 15.4929 12.6753 15.0943C12.4431 14.6963 12.6504 14.4805 12.8501 14.2821C13.0289 14.1038 13.2483 13.8173 13.4476 13.5849C13.6461 13.3524 13.7123 13.1865 13.8451 12.921C13.9779 12.6553 13.9114 12.4228 13.8121 12.2236C13.7123 12.0245 12.9387 10.0556 12.5847 9.2685Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1687_3162"
        x1="1545.64"
        y1="3103.62"
        x2="1545.64"
        y2="0.556503"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1FAF38" />
        <stop offset="1" stop-color="#60D669" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1687_3162"
        x1="1600.5"
        y1="3214.34"
        x2="1600.5"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F9F9F9" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
      <clipPath id="clip0_1687_3162">
        <rect
          width="32"
          height="32.25"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SVG;
