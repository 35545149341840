export const pageContent = (slug) => {
  return `
{
    getPageContent(slug: "${slug}"){
    id
    title
    slug
    description
    sectionData{
        offset
        limit
        total
        sections{
            id
            position
            viewport_id
            section{
                id
                title
                status_id
                cards{
                    id
                    title
                    position
                    status_id
                    content{
                        id
                        data
                        meta_data
                        position
                        status_id
                        template_field{
                            template_code
                            template_label
                        }
                    }
                }
            }
        }
    }
    }
}`;
};
