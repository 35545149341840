import React from "react";
import "./mobile.scss";
import Streamscreen from "../../ui/streamscreen/streamscreen";
import Boxbanner from "../../ui/boxbanner/boxbanner";
import Categorybanner from "../../ui/categorybanner/categorybanner";
// import { Newscards } from "../desktop/desktopData";
// import Newscardsection from "../../ui/newscardsection/newscardsection";
import { extractYouTubeVideoId } from "../../../libs/utils";
import { Link } from "react-router-dom";
import Deals from "../../ui/deals/deals";
import Disclaimer from "../../ui/disclaimer/disclaimer";
import Advert from "../../ui/advert/advert";
import DailyDeals from "../../ui/dailyDeals/dailyDeals";

const Mobile = ({
  pending,
  leftBanners,
  rightBanners,
  categories,
  youtubeURL,
  deals,
  dailyDeals,
}) => {
  return (
    <div className="mobileContent">
      <div>
        <Streamscreen youtubeURL={youtubeURL} />
      </div>

      <div className="dailyDeal">
        <DailyDeals img={dailyDeals} />
      </div>

      <div>
        <div className="banners">
          {/* RETURN TO "banners" AFTER LAUNCH */}
          {leftBanners.map((banner, index) => (
            <Boxbanner
              key={index}
              image={banner.image}
              alt={banner.alt_text}
              link={banner.link}
            />
          ))}
        </div>
        <div className="banners">
          {/* RETURN TO "banners" AFTER LAUNCH */}
          {rightBanners.map((banner, index) => (
            <Boxbanner
              key={index}
              image={banner.image}
              alt={banner.alt_text}
              link={banner.link}
            />
          ))}
        </div>
      </div>
      {/* <div className="mobiledeals">
        {deals.map((deal, index) => {
          const youtubeID = extractYouTubeVideoId(deal.url);

          return (
            <Link to={`/${youtubeID}`} key={index} className="deals">
              <Deals videoID={youtubeID} title={deal.text} />
            </Link>
          );
        })}
      </div> */}
      {/* <div className="categories">
        {categories.map((category, index) => (
          <Categorybanner
            key={index}
            image={category.image}
            title={category.alt_text}
            link={category.link}
          />
        ))}
      </div> */}
      {/* <div className="news">
        {Newscards.map((newscard, index) => (
          <Newscardsection
            key={index}
            mainTitle={newscard.mainTitle}
            section={newscard.section}
            title={newscard.title}
            info={newscard.info}
          />
        ))}
        {Newscards.map((newscard, index) => (
          <Newscardsection
            key={index}
            mainTitle={newscard.mainTitle}
            section={newscard.section}
            title={newscard.title}
            info={newscard.info}
          />
        ))}
      </div> */}
      <Advert />
      <Disclaimer />
    </div>
  );
};

export default Mobile;
