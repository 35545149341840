/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./streamscreen.scss";
import ReactPlayer from "react-player/youtube";

const Streamscreen = ({ youtubeURL, title, main = false, large = false }) => {
  const url = youtubeURL;

  return (
    <div className={!large ? "streamscreen" : "streamscreen_large"}>
      {main && (
        <div className="streamscreen__main-title">
          <h3>{title}</h3>
        </div>
      )}
      <div className={!large ? "screen" : "screen_large"}>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          playing={main}
          loop={true}
          controls={true}
        />
      </div>
    </div>
  );
};

export default Streamscreen;
