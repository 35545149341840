export const Contact = [
  {
    icon: "instagramPink",
    info: "https://www.instagram.com/officialkongaTV/",
    contact: "OfficialKongaTV",
  },
  {
    icon: "twitterPink",
    info: "https://x.com/OfficialKongaTV?t=GoG9vdYf_jTbiOj35xAa8A&s=09",
    contact: "OfficialKongaTV",
  },
  // {
  //   icon: "whatsappPink",
  //   info: "whatsapp://send?text=Welcome to KongaTv!&phone=09070038400",
  //   contact: "09070038400",
  // },
  {
    icon: "facebookPink",
    info: "https://www.facebook.com/OfficialKongaTV",
    contact: "KongaTV",
  },
  {
    icon: "youtubePink",
    info: "https://www.youtube.com/@Officialkongatv",
    contact: "KongaTV",
  },
];
