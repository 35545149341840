import { useEffect, useState } from "react";
import { normalizeContentCards, normalizePageContent } from "../libs/utils";
import { CONSTANTS } from "../helpers/constants";
import Homepage from "../services/homepage.service";

const useContent = () => {
  const [pending, setPending] = useState(false);
  const [banners, setBanners] = useState([]);
  const [leftBanners, setLeftBanners] = useState([]);
  const [rightBanners, setRightBanners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [deals, setDeals] = useState([]);
  const [dailyDeals, setDailyDeals] = useState([]);
  const [youtubeURL, setYoutubeURL] = useState("");

  const homePageData = async () => {
    try {
      setPending(true);
      const data = await Homepage.homepageData(CONSTANTS.HOMEPAGE_SLUG)
        .then(normalizePageContent, true)
        .then((res) => {
          const banners = res?.banners;
          const categories = res?.category;
          const youtubeURL = res?.youtube;
          const deals = res?.deals;
          const dailyDeals = res?.dailyDeals;
          const homePageBanners = normalizeContentCards(banners);
          const homePageCategories = normalizeContentCards(categories);
          const homePageScreen = normalizeContentCards(youtubeURL);
          const homePageDeals = normalizeContentCards(deals);
          const homePageDailyDeals = normalizeContentCards(dailyDeals);

          return {
            homePageBanners,
            homePageCategories,
            homePageScreen,
            homePageDeals,
            homePageDailyDeals,
          };
        });

      const homePageBanners = data.homePageBanners; // Extract the array
      const midpoint = Math.ceil(homePageBanners.length / 2);

      const homePageCategories = data.homePageCategories; // Extract the array
      const homePageScreen = data.homePageScreen; // Extract the array

      const homePageDeals = data.homePageDeals; // Extract the array
      const homePageDailyDeals = data.homePageDailyDeals; // Extract the array

      const firstArray = homePageBanners.slice(0, midpoint);
      const secondArray = homePageBanners.slice(midpoint);

      setBanners(homePageBanners);

      setLeftBanners(firstArray);
      setRightBanners(secondArray);

      setCategories(homePageCategories);

      setYoutubeURL(homePageScreen[0].url);

      setDeals(homePageDeals);
      setDailyDeals(homePageDailyDeals);

    } catch (error) {
      console.error("Error fetching homepage data:", error);
      // Handle the error as needed, for example, set an error state.
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    homePageData();
  }, []);

  return [
    pending,
    banners,
    leftBanners,
    rightBanners,
    categories,
    deals,
    youtubeURL,
    dailyDeals,
  ];
};

export default useContent;
