import React from "react";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import "./layout.scss";

const Layout = ({ children }) => {
  return (
    <div className="main">
      <div className="nav">
        <Navbar />
      </div>
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
